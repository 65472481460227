import { render, staticRenderFns } from "./betRecord.vue?vue&type=template&id=450659a2&scoped=true&"
import script from "./betRecord.vue?vue&type=script&lang=js&"
export * from "./betRecord.vue?vue&type=script&lang=js&"
import style0 from "./betRecord.vue?vue&type=style&index=0&id=450659a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450659a2",
  null
  
)

export default component.exports